<template lang="pug">
  .box.is-success
    Success
</template>

<script>
import Success from "@/components/Success.vue";
import { mapActions } from "vuex";

export default {
  components: {
    Success
  },
  methods: {
    ...mapActions({
      removeCurrentService: "services/removeCurrent",
      resetForm: "form/reset"
    })
  },
  created() {
    this.removeCurrentService();
    this.resetForm();
  }
};
</script>
