<template lang="pug">
  .success
    .success__body
      p.success__text После печати документов оплатите счет в терминале или обратитесь к одному из наших операторов для оплаты.
    .success__footer
      router-link.success__button.button.is-large.is-upper(
        to='/'
      ) Ок
</template>

<script>
export default {};
</script>

<style lang="scss">
.success {
  display: flex;
  flex-direction: column;

  &__body {
    flex-grow: 1;
  }

  &__text {
    color: $black;
    font-size: 22px;
    line-height: 1.5;
  }

  &__footer {
    display: flex;
    justify-content: center;
    flex-shrink: 0;
    margin-top: 60px;
    margin-bottom: 30px;
  }

  &__button {
  }
}
</style>
